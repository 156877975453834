@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
   /* Hide scrollbar for Chrome, Safari and Opera */
   .no-scrollbar::-webkit-scrollbar {
      display: none;
   }
   /* Hide scrollbar for IE, Edge and Firefox */
   .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
   }
}

:root {
   --primary-color-darker: #0051cc;
   --primary-color: #2563eb;
   --primary-color-lighter: #3b82f6;
   --primary-color-lighter-200: #93c5fd;
   --primary-color-lightest: #dbeafe;
   /*blue-600, blue-500, blue-100*/

   --secondary-color-darkest: #475569;
   --secondary-color-darker: #64748b;
   --secondary-color: #94a3b8;
   --secondary-color-lighter: #cbd5e1;
   /*slate-600 slate-500, slate-400, slate-300*/

   --tertiary-color-darker: #ca8b04;
   --tertiary-color: #facc15;
   --tertiary-color-lighter: #fde047;
   /*yellow-600 yellow-400, yellow-300*/

   --gray-btn: #848484;
   --gray-text: #242731;

   --sidebar-width-base: 100px;
   --sidebar-width: 200px;
}

@layer base {
   html {
      --primary-darker: var(--primary-color-darker);
      --primary: var(--primary-color);
      --primary-lighter: var(--primary-color-lighter);
      --primary-lighter-200: var(--primary-color-lighter-200);
      --primary-lightest: var(--primary-color-lightest);

      --secondary-darkest: var(--secondary-color-darkest);
      --secondary-darker: var(--secondary-color-darker);
      --secondary: var(--secondary-color);
      --secondary-lighter: var(--secondary-color-lighter);

      --tertiary-darker: var(--tertiary-color-darker);
      --tertiary: var(--tertiary-color);
      --tertiary-lighter: var(--tertiary-color-lighter);

      --gray-btn-light: var(--gray-btn);
      --gray-btn-darker: var(--gray-text);

      font-family: "Poppins", sans-serif;

      background: white;
   }
}

@layer utilities {
   .clip-checkmark {
      clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
   }
}

@layer components {
   .filter-blue {
      filter: invert(24%) sepia(75%) saturate(5071%) hue-rotate(211deg) brightness(95%)
         contrast(106%);
   }

   .filter-yellow {
      filter: invert(73%) sepia(25%) saturate(3235%) hue-rotate(4deg) brightness(100%) contrast(94%);
   }

   .filter-white {
      filter: invert(97%) sepia(86%) saturate(2%) hue-rotate(257deg) brightness(115%) contrast(100%);
   }

   .wrapper {
      @apply pb-5 md:mx-sidebar-base lg:mx-sidebar;
   }

   .wrapper-right {
      @apply md:mr-sidebar-base lg:mr-sidebar;
   }

   .container {
      @apply mx-auto flex min-h-full w-[85%] max-w-[420px] flex-col items-center md:min-h-0;
   }

   .container.left {
      @apply md:ml-[12%];
   }

   .fixed-btn {
      @apply flex flex-col items-center fixed bottom-[77px] z-[2] w-screen bg-white p-6 md:bottom-0 md:w-[calc(100%-var(--sidebar-width-base))] md:pb-14 lg:w-[calc(100%-var(--sidebar-width))];
   }

   .container-new-gray {
      @apply w-full min-h-screen mx-auto bg-[#FCFDFF] md:bg-[#F3F3F3] rounded-t-xl flex flex-col items-center;
   }

   .container-new-blue {
      @apply w-full min-h-screen mx-auto bg-[#FCFDFF] md:bg-[#EDF5FC] rounded-t-xl flex flex-col items-center;
   }
}

.poppins-thin {
   font-family: "Poppins", sans-serif;
   font-weight: 100;
   font-style: normal;
}

.poppins-extralight {
   font-family: "Poppins", sans-serif;
   font-weight: 200;
   font-style: normal;
}

.poppins-light {
   font-family: "Poppins", sans-serif;
   font-weight: 300;
   font-style: normal;
}

.poppins-regular {
   font-family: "Poppins", sans-serif;
   font-weight: 400;
   font-style: normal;
}

.poppins-medium {
   font-family: "Poppins", sans-serif;
   font-weight: 500;
   font-style: normal;
}

.poppins-semibold {
   font-family: "Poppins", sans-serif;
   font-weight: 600;
   font-style: normal;
}

.poppins-bold {
   font-family: "Poppins", sans-serif;
   font-weight: 700;
   font-style: normal;
}

.poppins-extrabold {
   font-family: "Poppins", sans-serif;
   font-weight: 800;
   font-style: normal;
}

.poppins-black {
   font-family: "Poppins", sans-serif;
   font-weight: 900;
   font-style: normal;
}

.poppins-thin-italic {
   font-family: "Poppins", sans-serif;
   font-weight: 100;
   font-style: italic;
}

.poppins-extralight-italic {
   font-family: "Poppins", sans-serif;
   font-weight: 200;
   font-style: italic;
}

.poppins-light-italic {
   font-family: "Poppins", sans-serif;
   font-weight: 300;
   font-style: italic;
}

.poppins-regular-italic {
   font-family: "Poppins", sans-serif;
   font-weight: 400;
   font-style: italic;
}

.poppins-medium-italic {
   font-family: "Poppins", sans-serif;
   font-weight: 500;
   font-style: italic;
}

.poppins-semibold-italic {
   font-family: "Poppins", sans-serif;
   font-weight: 600;
   font-style: italic;
}

.poppins-bold-italic {
   font-family: "Poppins", sans-serif;
   font-weight: 700;
   font-style: italic;
}

.poppins-extrabold-italic {
   font-family: "Poppins", sans-serif;
   font-weight: 800;
   font-style: italic;
}

.poppins-black-italic {
   font-family: "Poppins", sans-serif;
   font-weight: 900;
   font-style: italic;
}

.inter-thin {
   font-family: "Inter", sans-serif;
   font-optical-sizing: auto;
   font-weight: 100;
   font-style: normal;
   font-variation-settings: "slnt" 0;
}

.inter-light {
   font-family: "Inter", sans-serif;
   font-optical-sizing: auto;
   font-weight: 200;
   font-style: normal;
   font-variation-settings: "slnt" 0;
}

.inter-normal {
   font-family: "Inter", sans-serif;
   font-optical-sizing: auto;
   font-weight: 400;
   font-style: normal;
   font-variation-settings: "slnt" 0;
}

.inter-semibold {
   font-family: "Inter", sans-serif;
   font-optical-sizing: auto;
   font-weight: 500;
   font-style: normal;
   font-variation-settings: "slnt" 0;
}

.inter-bold {
   font-family: "Inter", sans-serif;
   font-optical-sizing: auto;
   font-weight: 600;
   font-style: normal;
   font-variation-settings: "slnt" 0;
}

.inter-black {
   font-family: "Inter", sans-serif;
   font-optical-sizing: auto;
   font-weight: 700;
   font-style: normal;
   font-variation-settings: "slnt" 0;
}

.inter-dark {
   font-family: "Inter", sans-serif;
   font-optical-sizing: auto;
   font-weight: 900;
   font-style: normal;
   font-variation-settings: "slnt" 0;
}

/* Toggle styles */

.SwitchRoot {
   width: 52px;
   height: 25px;
   background-color: var(--primary-color-lighter);
   border-radius: 9999px;
   position: relative;
   box-shadow: 0 2px 10px var(--black-a7);
   -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.SwitchRoot:focus {
   box-shadow: 0 0 0 2px black;
}
.SwitchRoot[data-state="checked"] {
   background-color: black;
}

.SwitchThumb {
   display: block;
   width: 15px;
   height: 15px;
   background-color: white;
   border-radius: 9999px;
   box-shadow: 0 2px 2px var(--black-a7);
   transition: transform 100ms;
   transform: translateX(2px);
   will-change: transform;
}
.SwitchThumb[data-state="checked"] {
   transform: translateX(19px);
}

.Label {
   color: black;
   font-size: 15px;
   line-height: 1;
}
